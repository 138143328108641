import React from 'react'
import product1 from '../assets/videos/product-v2.MP4' // Ensure the video file is in the correct path
import product2 from '../assets/videos/PRODUCT-V1.MP4' // Ensure the video file is in the correct path
const ProductVideo = () => {
    // Dynamic height based on conditions or just use '50vh' for 50% of viewport height
    const videoHeight = '50vh'; // You can also use state or props to adjust this dynamically

    return (
        <section className='section-padding section-bottom'>
            <div className='container'>
                <h1 className='title'  data-aos="fade-up"  data-aos-duration="1000">product videos</h1>
                <div className='row mt-5'>
                    <div className='col-md-6'  data-aos="zoom-in-right" data-aos-duration="1000">
                        <div className='video-product'>
                            <video 
                                style={{ width: '100%', height: videoHeight }} 
                                controls
                            >
                                <source src={product1} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <div className='col-md-6 mt-3 mt-md-0'  data-aos="zoom-in-right"  data-aos-duration="1000">
                        <div className='video-product'>
                            <video 
                                style={{ width: '100%', height: videoHeight }} 
                                controls
                            >
                                <source src={product2} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductVideo