import React from 'react'
import product1 from '../assets/images/Product-1.png'
import product2 from '../assets/images/Product-2.png'
import product3 from '../assets/images/Product-3.png'
import product4 from '../assets/images/Product-4.png'
import product5 from '../assets/images/Product-5.png'
import product6 from '../assets/images/Product-7.png'
import product8 from '../assets/images/Product-6.png'
import product9 from '../assets/images/Product-8.png'

const ProductGallery = () => {  
    return (
        <section className='section-bottom'>
            <div className='container'>
                <h1 className='title'  data-aos="fade-up"  data-aos-duration="1000">products photo gallery</h1>
                <div className='row mt-2 mt-md-5'>
                    <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                        <div className='overflow-hidden position-relative product-gallery-main'>
                            <img src={product1} className='w-100 object-fit-contain' alt="gallery image 1" />
                            <div className='product-overlay'></div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                        <div className='overflow-hidden position-relative product-gallery-main'>
                            <img src={product2} className='w-100 object-fit-contain' alt="gallery image 2" />
                            <div className='product-overlay'></div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                        <div className='overflow-hidden position-relative product-gallery-main'>
                            <img src={product3} className='w-100 object-fit-contain' alt="gallery image 3" />
                            <div className='product-overlay'></div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                        <div className='overflow-hidden position-relative product-gallery-main'>
                            <img src={product4} className='w-100 object-fit-contain'  alt="gallery image 4" />
                            <div className='product-overlay'></div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                        <div className='overflow-hidden position-relative product-gallery-main'>
                            <img src={product5} className='w-100 object-fit-contain' alt="gallery image 5"  />
                            <div className='product-overlay'></div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                        <div className='overflow-hidden position-relative product-gallery-main'>
                            <img src={product6} className='w-100 object-fit-contain' alt="gallery image 6" />
                            <div className='product-overlay'></div>
                        </div>
                    </div>
                   
                    <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                        <div className='overflow-hidden position-relative product-gallery-main'>
                            <img src={product9} className='w-100 object-fit-contain' alt="gallery image 7" />
                            <div className='product-overlay'></div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-4 col-6 my-3' data-aos="zoom-in-right" data-aos-duration="1000">
                        <div className='overflow-hidden position-relative product-gallery-main'>
                            <img src={product8} className='w-100 object-fit-contain' alt="gallery image 8" />
                            <div className='product-overlay'></div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </section>
    )
}

export default ProductGallery
